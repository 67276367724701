import React, {setGlobal, useGlobal}from 'reactn';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {InputAdornment, IconButton} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {autoComplete} from "@material-ui/lab";
import Axios from 'axios';
import SLHSLogoHeader from './slhsLogoHeader';
import LogoHeader from './logoHeader';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#30488e"
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [usernameError, setUserNameError] = React.useState(false);
  const [helperMessage, setHelperMessage] = React.useState('');
  const [saveUser, setSaveUser] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false);
  const [user, setUser] = useGlobal('user');
  
  
  const logIn = () => {
    return Axios.get(`${process.env.REACT_APP_URL}/signin`, {
      auth: {
        username: email,
        password: password,
      }
    })
    .then(data => {
      console.log('data', data)
      if(saveUser) {
        localStorage.token = data.data.token ;
      }
      setGlobal({
        user: {
          id: data.data.user._id,
          name: data.data.user.name,
          username: data.data.user.username,
          token: data.data.token,
      }});
      return data;
      
      // props.history.push('/classes');
      
      
    })
    .then(data => {
      
      if(data.data.user.username.split('@')[1] === 'seattlelutheran.org') {
          props.history.push('/addticket')
      } else {
          props.history.push('/classes');
      }
    })
    //add new then
    .catch(err => {
      console.log('error on signin ', err)
      let {data} = err.response;
      if(data.includes("Password")) {setPasswordError(true); setHelperMessage({Password: 'incorrect password'})}
      if(data.includes("Username")) {setUserNameError(true); setHelperMessage({Username: 'incorrect username'})}
    });
  }
  if(user.token) {
    let userDomain = user.username.split('@')[1];
    if(userDomain === 'hopeseattle.org') {
      props.history.push('/classes')
    }
    if(userDomain === 'seattlelutheran.org') {
      props.history.push('/addticket')
    }
    
  }
  return (
    <Container component="main" maxWidth="sm">
      {/* <CssBaseline /> */}
        <SLHSLogoHeader />
        
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          IT Support
        </Typography>
        
        <form className={classes.form}  noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            
            autoComplete="email"
            onChange={e => setEmail(e.target.value.toLowerCase())}
           
            error={usernameError}
            helperText={helperMessage.Username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            error={passwordError}
            helperText={helperMessage.Password}
            type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>

                  </InputAdornment>,
                }}
          />
          <FormControlLabel
            control={
              <Checkbox 
                value="remember" 
                color="primary" 
                name="saveUser" 
                onChange={e => setSaveUser(e.target.checked)} />}
            label="Remember me"
          />
          <Button
            
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => logIn()}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs={4} style={{ textAlign: "left"}}>
              <Link href="/restaurant/emailreset" variant="body2">
                {"Forgot password?"}
              </Link>
            </Grid>
            
          </Grid> */}
        </form>
      </div>
    </Container>
  );
}