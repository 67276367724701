import React, {setGlobal, useGlobal}from 'reactn';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from "@material-ui/core/Divider";
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
// import 'date-fns';
import Axios from 'axios';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: "75%",
    marginLeft: "12.5%",
  },
 
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  
}));

export default function CreateClass(props) {
  
  const classes = useStyles();
  const [teachers, setTeachers] = React.useState([]);
  const [user, setUser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  
  const [profileMenu, setProfileMenu] = React.useState(null);
  const [classDescription, setClassDescription] = React.useState('');
  const [currentOwner, setCurrentOwner] = React.useState('');
  let myTeacherArray = [];

 React.useEffect(() => {
   console.log('getting teachers');
  Axios.get(`${process.env.REACT_APP_URL}/allusers`, {
    headers: { Authorization: `Bearer ${user.token}` }
  })
  .then(users => {
    console.log('teachers from API', users);
    setTeachers(users.data)
  })
  .catch(err => console.log('error', err));
 
 }, []);
 
 const handleLogOut = () => {
  localStorage.clear();
  setUser({
    user: {
      username: null,
      telephone: null,
      name: null,
      address: null,
      token: null,
    }
    
  })
  props.history.push('/');
  }
  const createClass = (e) => {
    if(currentOwner && classDescription !== '') {
      Axios.post(`${process.env.REACT_APP_URL}/peter`, 
      {
        description: `${classDescription}`,
        owner: `${currentOwner}`,
        students: [],
      },
      {
        headers: {Authorization: `Bearer ${user.token}`}
      }
      )
      .then(data => {
        console.log('data from create class', data);
        setAlertOpen(true);
        setAlertMessage('New Class Created');
        setCurrentOwner('');
        setClassDescription('');
      })
      .catch(err => console.err(err));

    } else {
      setAlertOpen(true);
        setAlertMessage('You need a description and an Instructor');
        setCurrentOwner('');
        setClassDescription('');
    }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };



  if(!user.token) props.history.push('/')
  myTeacherArray = teachers.map((ele, index) => {
    return(
        <MenuItem key={index} value={ele._id}>{ele.name}</MenuItem>
    )
  })
  return (
   
      <div className={classes.paper}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Classes
          </Typography>
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
      <Divider height={"2px"}></Divider>
      <Select
          displayEmpty
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentOwner}
          onChange={e => setCurrentOwner(e.target.value)}
         
        >
          <MenuItem value="" disabled>
            Select Instructor
          </MenuItem>
          {myTeacherArray}
        </Select>
        <form className={classes.form}  noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="class-description"
            label="Class Description"
            name="classdes"
            value={classDescription}
            
            onChange={e => setClassDescription(e.target.value.toLowerCase())}
           
            
          />
          
          <Button
            
            
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => createClass()}
          >
            Create New Class
          </Button>
          
        </form>
        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      </div>
    
  );
}