import React, {Component} from 'reactn';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Signin from './usersignin';
import Classes from './classes';
import AddStudents from './addtoclasses';
import AddClass from './create-class';
import AddTicket from './addTicket';
import SLHSSignin from './slhsUserSignin';
import AdminTickets from './adminTickets';
import StudentCopy from './studentcopy';
import Grading from './elemGrading';
import Printing from './elemPrint';
import MPrinting from './middlePrint';
import UploadCSV from './uploadcsv';
import UploadAttendance from './uploadAttendance';
import './App.css';


class App extends Component {
  constructor(props){
    super(props);
    this.state ={}
  }
  render() {
    return(
      <Router>
      <div className="App">
        <Switch>
       
        <Route 
          exact path="/" component={Signin}
          />
        <Route 
          exact path="/slhs" component={SLHSSignin}
          />
        <Route 
          exact path="/grading" component={Grading}
          />
        <Route 
          exact path="/classes" component={Classes}
          />
        <Route 
          exact path="/addstudents" component={AddStudents}
          />
        <Route 
          exact path="/addclasses" component={AddClass}
          />
        <Route 
          exact path="/addticket" component={AddTicket}
          />
        <Route 
          exact path="/admintickets" component={AdminTickets}
          />
        <Route 
          exact path="/studentcopy" component={StudentCopy}
          />
        <Route 
          exact path="/printing" component={Printing}
          />
        <Route 
          exact path="/mprinting" component={MPrinting}
          />
        <Route 
          exact path="/upload" component={UploadCSV}
          />
        <Route 
          exact path="/uploadattendance" component={UploadAttendance}
          />
        <Route 
          path="*" component={Signin}
          />
    
      </Switch>
      </div>

    </Router>

)}};
export default App;
