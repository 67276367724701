

export  default function letterGrade (x) {
  let returnValue = " ";
  // if(x) returnValue = ' ';
  if(x > 0 && x < 60) returnValue = 'F';
  if(x >= 60 && x < 63) returnValue = "D-";
  if(x >= 63 && x < 67) returnValue = " D";
  if(x >= 67 && x < 70) returnValue = "D+";
  if(x >= 70 && x < 73) returnValue = "C-";
  if(x >= 73 && x < 77) returnValue = " C";
  if(x >= 77 && x < 80) returnValue = "C+";
  if(x >= 80 && x < 83) returnValue = "B-";
  if(x >= 83 && x <  87) returnValue = " B";
  if(x >= 87 && x  < 90) returnValue = "B+";
  if(x >= 90 && x < 93) returnValue = 'A-';
  if(x >= 93 && x < 98) returnValue = " A";
  if(x >= 98) returnValue = 'A+';
  if(x === 0) returnValue = ' ';
  
  
  

  console.log('x', x, 'returnValue', returnValue)
  return(returnValue)
}