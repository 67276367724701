import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

// import DateFnsUtils from '@date-io/date-fns';

import { TextField } from '@material-ui/core';
// import 'date-fns';

import Axios from 'axios';
import {Paper} from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  
  
}));





export default function GradingComment(props) {
  
  const classes = useStyles();
  
  
  const [comments, setComments] = React.useState(props.comments);
  const [user, setuser] = useGlobal('user');

  const handleNoteChange = e => {
    setComments({...comments, [e.target.name]: e.target.value})
  }
  
  const handleUpdate = () => {
    console.log('in hanlde update', comments);
    Axios.put(`${process.env.REACT_APP_URL}/updateComments`,{
      id: comments._id,
      t1Comment: comments.t1Comment,
      t2Comment: comments.t2Comment,
      t3Comment: comments.t3Comment,
      
    }, {headers: { Authorization: `Bearer ${user.token}` }}, 
      )
      .then(data => console.log('data from put',data))
      .catch(err => console.log(err));
  }
console.log('in grading row props::', props)

return (
  <Paper style={{width: "80%", margin: "1% auto"}} elevation={6} variant="outlined">
      <Grid container>
        <Grid item lg={12} sm={12}>
          <Typography>Trimester 1 Comment</Typography>
          <TextField 
          multiline
          rows={6}
          value={comments.t1Comment} 
          fullWidth 
          variant="outlined"
          onChange={handleNoteChange}
          name="t1Comment"
          onBlur={() => handleUpdate()}
          >
          </TextField>
        </Grid>
        <Grid item lg={12} sm={12}>
          <Typography>Trimester 2 Comment</Typography>
          <TextField 
          multiline
          rows={6}
          value={comments.t2Comment} 
          fullWidth 
          variant="outlined"
          onChange={handleNoteChange}
          name="t2Comment"
          onBlur={() => handleUpdate()}
          >
          </TextField>
        </Grid>
        <Grid item lg={12} sm={12}>
          <Typography>Trimester 3 Comment</Typography>
          <TextField 
            multiline
            rows={6}
            value={comments.t3Comment} 
            fullWidth 
            variant="outlined"
            onChange={handleNoteChange}
            name="t3Comment"
            onBlur={() => handleUpdate()}
          >
          </TextField>
        </Grid>
         
      </Grid>
     </Paper> 
  );
}
