import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';
import {logo} from './base64';

import Decimal from './decimal';
import letterGrade from './letterGrade';

const greyColor = '#ECECEC';
const teachers = [{name: 'Timothy Allen', grade: '6'}, {name: 'Tim Caudle', grade: '7'}, {name: 'Sara Kunkel', grade: '8'}]
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default async function PDFMaker(students) {
  let merritPrincipal =[];
  let merritScholar = [];
  let merritHonor = [];
  let merritPrincipal2 =[];
  let merritScholar2 = [];
  let merritHonor2 = [];
  let merritPrincipal3 =[];
  let merritScholar3 = [];
  let merritHonor3 = [];
  let ddContent = [];
  console.log('student in pdf', students)
  
    // let id = students[i]['Unique User ID'];
    
    
    
    
    

    
    for (let i = 0; i < students.length; i++) {
      console.log('student', students[i]);
      let gpaTotal1 = 0;
      let gpaTotal2 = 0;
      let gpaTotal3 = 0;
      let numClasses = 0;
      let thisTeacher = teachers.filter(ele => ele.grade === students[i].grade)
      let gradesM = await axios.get(`${process.env.REACT_APP_URL}/middleGrades/${students[i].sis_id}`);
      let attendance = await axios.get(`${process.env.REACT_APP_URL}/attendanceByStudent/${students[i].sis_id}`)
      console.log('attendance', attendance);
      let tempArray = gradesM.data.map(ele => ele.CourseCode)
      let tempSet = new Set(tempArray);
      let thisArray = [...tempSet];
      console.log('gradesM', gradesM.data);
      console.log('set', tempSet);
      console.log('atta', thisArray);
      let studentGrades = gradesM.data;
    let classList = [];
    
    classList.push([{text: 'Classes', fillColor: greyColor}, {text: 'Fall',fillColor: greyColor}, {text: 'Winter', fillColor: greyColor}, {text: 'Spring', fillColor: greyColor}])
      // [{text: 'classes', fillColor: greyColor}, {text: 'Fall',fillColor: greyColor}, {text: 'Winter', fillColor: greyColor}, {text: 'Spring', fillColor: greyColor}],
    // for (let j= 0; j < studentGrades.length; j++) {
    //   console.log(studentGrades[j].CourseName)
    //   classList.push( [studentGrades[j].CourseName, studentGrades[j].Grades, {}, {}])
    // }
    for (let x = 0; x < studentGrades.length; x ++) {

      let tempGrade = studentGrades[x];
      
      console.log('temp Grades', tempGrade);
      console.log('________________________________')
      // let tempGPAContribution1 = Decimal(tempGrade.t1Grade);
      let tempGPAContribution1 = Decimal(tempGrade.t1Grade) * tempGrade.weight;
      let tempLetterGrade1 = letterGrade(tempGrade.t1Grade);
      console.log('tempLetterGrade 1', tempLetterGrade1);
      console.log('________________________________')
      let tempGPAContribution2 = Decimal(tempGrade.t2Grade) * tempGrade.weight;
      let tempLetterGrade2 = letterGrade(tempGrade.t2Grade);
      let tempGPAContribution3 = Decimal(tempGrade.t3Grade) * tempGrade.weight;
      let tempLetterGrade3 = letterGrade(tempGrade.t3Grade);
      console.log('Decimal value', tempGPAContribution1);
      gpaTotal1 = gpaTotal1 + tempGPAContribution1;
      console.log('Gpa Totals 1', gpaTotal1);
      gpaTotal2 = gpaTotal2 + tempGPAContribution2;
      console.log('Gpa Totals 2', gpaTotal2);
      gpaTotal3 = gpaTotal3 + tempGPAContribution3;
      console.log('Gpa Totals 3', gpaTotal3);
      // classList.push([{text: `${studentGrades[x].description}`, fontSize: '10'},{text: `${studentGrades[x].t1Grade}`, fontSize: '10'},{},{}]);
      console.log('category', tempGrade.category)
      if(x < studentGrades.length-2) {
        // console.log("temp a=category",tempGrade.category)
        if((studentGrades[x].category !== studentGrades[x+1].category) ) {
          classList.push([{text: `${studentGrades[x].description}`, fontSize: '10'},{text: `${tempLetterGrade1}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade2}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade3}`, fontSize: '10', alignment: 'justified'}]);
          classList.push([{text: `Electives`,fillColor: greyColor, fontSize: '10', colSpan: 4},{},{},{}]);
        } else {
          classList.push([{text: `${studentGrades[x].description}`, fontSize: '10'},{text: `${tempLetterGrade1}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade2}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade3}`, fontSize: '10', alignment: 'justified'}]);
        }

      } else {

        classList.push([{text: `${studentGrades[x].description}`, fontSize: '10'},{text: `${tempLetterGrade1}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade2}`, fontSize: '10', alignment: 'justified'},{text: `${tempLetterGrade3}`, fontSize: '10', alignment: 'justified'}]);
      }
      
      
      

    }
    
   
    console.log('Classlist',classList)
    const gradeTable = {
      table: {
        widths: [15, 65],
        headerRows: 1,
        body: [
          [{text: 'Grade Values', colSpan: 2, fontSize: '8', bold: true}, {}],
          [{text:'A+',fontSize: '8'}, {text: '98 - 100%', fontSize: '8'}],
          [{text:'A', fontSize: '8'}, {text: '93 - 97%', fontSize: '8'}],
          [{text:'A-', fontSize: '8'}, {text: '90 - 92%',fontSize: '8'}],
          [{text:'B+', fontSize: '8'}, {text: '87 - 89%', fontSize: '8'}],
          [{text:'B', fontSize: '8'}, {text: '83 - 86%', fontSize: '8'}],
          [{text:'B-', fontSize: '8'}, {text: '80 - 83%', fontSize: '8'}],
          [{text:'C+', fontSize: '8'}, {text: '77 - 79%',fontSize: '8'}],
          [{text:'C', fontSize: '8'}, {text: '73 - 76%', fontSize: '8'}],
          [{text:'C-', fontSize: '8'}, {text: '70 - 72%', fontSize: '8'}],
          [{text:'D+',fontSize: '8'}, {text: '67 - 69%', fontSize: '8'}],
          [{text:'D', fontSize: '8'}, {text: '63 - 66%', fontSize: '8'}],
          [{text:'D-', fontSize: '8'}, {text: '60 - 62%', fontSize: '8'}],
          [{text:'F', fontSize: '8'}, {text: '59% and below', fontSize: '8'}],
        ],
        alignment: 'right'
      }
    }
    const promoTable = {
      table: {
        widths: [70, 10],
        body: [
          [{text:'Promoted to Grade', fontSize: '8'}, {text: '  ', fontSize: '8', }],
          [{text:'Placed in Grade', fontSize: '8'}, {text: '   ', fontSize: '8'}],
          [{text:'Retained in Grade', fontSize: '8'}, {text: '   ', fontSize: '8'}],
        ],
        alignment: 'center'
      }
    }
    let gpaDisplay1 = gpaTotal1 > 0 ? (gpaTotal1 / 28).toFixed(2) : ' ';
    let gpaDisplay2 = gpaTotal2 > 0 ? (gpaTotal2 / 28).toFixed(2) : ' ';
    let gpaDisplay3 = gpaTotal3 > 0 ? (gpaTotal3 / 28).toFixed(2) : ' ';
    console.log('gpaTotals', gpaTotal2)
    // add students to honor roll.
    if(gpaDisplay1 >= 3.3 && gpaDisplay1 < 3.5) {
      console.log('Scholastic Merit')
      merritScholar.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Scholastic Merit',
        gpaPrint: gpaDisplay1,
        order: 3

      });
    }
    if(gpaDisplay1 >= 3.5 && gpaDisplay1 < 4) {
      console.log('Honor Roll');
      merritHonor.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Honor Roll',
        gpaPrint: gpaDisplay1,
        order: 2

      });
    }
    if(gpaDisplay1 >= 4) {
      console.log('Principals List');
      merritPrincipal.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Principals List',
        gpaPrint: gpaDisplay1,
        order: 1

      });
    }
    if(gpaDisplay2 >= 3.3 && gpaDisplay2 < 3.5) {
      console.log('Scholastic Merit')
      merritScholar2.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Scholastic Merit',
        gpaPrint: gpaDisplay2,
        order: 3

      });
    }
    if(gpaDisplay2 >= 3.5 && gpaDisplay2 < 4) {
      console.log('Honor Roll');
      merritHonor2.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Honor Roll',
        gpaPrint: gpaDisplay2,
        order: 2

      });
    }
    if(gpaDisplay2 >= 4) {
      console.log('Principals List');
      merritPrincipal2.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Principals List',
        gpaPrint: gpaDisplay2,
        order: 1

      });
    }
    if(gpaDisplay3 >= 3.3 && gpaDisplay3 < 3.5) {
      console.log('Scholastic Merit')
      merritScholar3.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Scholastic Merit',
        gpaPrint: gpaDisplay3,
        order: 3

      });
    }
    if(gpaDisplay3 >= 3.5 && gpaDisplay3 < 4) {
      console.log('Honor Roll');
      merritHonor3.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Honor Roll',
        gpaPrint: gpaDisplay3,
        order: 2

      });
    }
    if(gpaDisplay3 >= 4) {
      console.log('Principals List');
      merritPrincipal3.push({
        name: `${students[i].firstname} ${students[i].lastname}`,
        list: 'Principals List',
        gpaPrint: gpaDisplay3,
        order: 1

      });
    }
   
    let statsTable = {
      style: 'tableFont',
      table: {
        headerRows: 2,
        widths: ['*', 'auto', 'auto', 'auto'],
        body: [
          [{text: 'Trimester Statistics', colSpan: 4, bold: true, fontSize: '10', fillColor: greyColor},{},{},{}],
          ['', 'Fall', 'Winter', 'Spring'],
          ['GPA',{text: `${gpaDisplay1}`, alignment: 'center'},{text: `${gpaDisplay2}`,alignment: 'center'}, {text: `${gpaDisplay3}`, alignment: 'center'}],
          ['School Days', {text:'57', alignment: 'center'},{text:'58', alignment: 'center'},{text:'56', alignment: 'center'} ],
          ['Days Absent', {text:`${attendance.data[0].t1Absent}`, alignment: 'center'}, {text:`${attendance.data[0].t2Absent}`, alignment: 'center'}, {text:`${attendance.data[0].t3Absent}`, alignment: 'center'}],
          // ['Days Absent', {text:`0`, alignment: 'center'}, {text:'0', alignment: 'center'}, {text:'0', alignment: 'center'}],
          ['Days Tardy (Exc / Unexc)', {text:`${attendance.data[0].t1Tardy}`, alignment: 'center'}, {text:`${attendance.data[0].t2Tardy}`, alignment: 'center'}, {text:`${attendance.data[0].t3Tardy}`, alignment: 'center'}],
          // ['Number of Classes', {text:`${studentGrades.length}`, alignment: 'center'}, {text:``, alignment: 'center'}, {text:'', alignment: 'center'}]
          // ['Days Tardy (Exc / Unexc)', {text:`(0,0)`, alignment: 'center'}, {text:'0/0', alignment: 'center'}, {text:'0/0', alignment: 'center'}]
        ]
      }
    }
    



    ddContent.push(
      {columns: [
        {text: '', width:'*'},
        {image: logo , alignment: 'center', width: 150},
        {text: '', width:'*'},
        // {image: logo , alignment: 'center'},
      ]},
      {text: '\n\n'},
      {columns: [
        [
        {text: `${students[i].firstname} ${students[i].lastname}`, alignment: 'center', fontSize: '14', bold: true},
        {text: 'Student', alignment: 'center', fontSize: '10'},
        {text: '\n'},
       

   ],

        [{text: '4456 42nd Ave SW', width: "*", alignment: 'center', fontSize: '10'},
          {text: 'Seattle, WA, 98126', alignment: 'center', fontSize: '10'},
        {text: 'hopeseattle.org', alignment: 'center', fontSize: '10'},
      {text: '(206) 935-8500', alignment: 'center', fontSize: '10'}]
      ]},
      {text: 'Progress Report', width: "*", alignment: 'center', fontSize: '14'}, 
      {text: `${students[i].grade}th Grade 2022 - 2023\n\n`, alignment: 'center', fontSize: '14', bold: 'true'},
      {
        columns: [
          [ 
            {text: `${thisTeacher[0].name}`, alignment: 'center', width: '*', fontSize: '12' },
            {text: 'Teacher', alignment: 'center', width: '*', fontSize: '10' },
          ],
          [
            {text: 'Kristen Okayabashi', alignment:'center', fontSize: '12', width: 'auto'},
            {text: 'Principal\n\n\n', fontSize: '10', alignment: 'center', width: 'auto'},
          ]
        ]
      },
      { layout: 'noBorders',
        table:{
        widths: ['30%', '5%','*'],
       
        body: [
          //the fiest array element is first column etc
          [
            //start of column 1
            [
                {text: '\n'},
                gradeTable,
                {text: '\n\n'},
                promoTable,
                {text: '\n\n\n\n\n'},
                {text: '____________________', alignment: 'center'},
                {text: students[i].grade === '8' ? 'Principal\n\n\n' : 'Teachers Signature\n\n\n', alignment: 'center', fontSize: '10'},
            ]
            //end of column 1
            , 
            [
              [{}]
            ],
            //start of column 2
            [ 
             
              {text: 'Trimester Grades', alignment: 'right'},
             {
            table: {
              headerRows: 1,
              widths: ['*', 34, 35 , 35],
              body: classList,
            }
          },
          {text: '\n\n\n\n\n'},
          //trying for 2 columns here
          
          statsTable,

            ]
            // end of column 2
          ]]
      }},
      {text: '', pageBreak: 'after'}
      )
    }
   // end of if statement

   merritPrincipal.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritHonor.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritScholar.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritPrincipal2.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritHonor2.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritScholar2.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritPrincipal3.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritHonor3.sort((a, b) => b.gpaPrint - a.gpaPrint)
   merritScholar3.sort((a, b) => b.gpaPrint - a.gpaPrint)
    console.log('merritPricipal', merritPrincipal);
  //Page one of Merrit list
   let honorTableBody = [];
   honorTableBody.push([{text: 'Honor Roll Students Trimester 1', colSpan: 2, bold: true, fontSize: '12', fillColor: greyColor},{}]);
   if(merritPrincipal.length > 0) {
     honorTableBody.push([{text: 'Principals List', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritPrincipal.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
   if(merritHonor.length > 0) {
     honorTableBody.push([{text: 'Honor Roll', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritHonor.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   })
   if(merritScholar.length > 0) {
     honorTableBody.push([{text: 'Scholastic Merit', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);

   }
   merritScholar.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
   // page 2 for Trimester 2
   honorTableBody.push([{text: 'Honor Roll Students Trimester 2', colSpan: 2, bold: true, fontSize: '12', fillColor: greyColor},{}]);
   if(merritPrincipal2.length > 0) {
     honorTableBody.push([{text: 'Principals List', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritPrincipal2.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
   if(merritHonor2.length > 0) {
     honorTableBody.push([{text: 'Honor Roll', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritHonor2.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   })
   if(merritScholar2.length > 0) {
     honorTableBody.push([{text: 'Scholastic Merit', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);

   }
   merritScholar2.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
   // page for trimester 3
   honorTableBody.push([{text: 'Honor Roll Students Trimester 3', colSpan: 2, bold: true, fontSize: '12', fillColor: greyColor},{}]);
   if(merritPrincipal3.length > 0) {
     honorTableBody.push([{text: 'Principals List', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritPrincipal3.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
   if(merritHonor3.length > 0) {
     honorTableBody.push([{text: 'Honor Roll', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);
   }
   merritHonor3.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   })
   if(merritScholar3.length > 0) {
     honorTableBody.push([{text: 'Scholastic Merit', colSpan: 2, bold: true, ontSize: '12', fillColor: greyColor}, {}]);

   }
   merritScholar3.forEach(ele => {
     honorTableBody.push([{text: `${ele.name}`},{text: `${ele.gpaPrint}`}])
   });
  //  let newHonorTableBody = [];
  //  newHonorTableBody.push(honorTableBody);



   let honorTable = {
     style: 'tableFont',
     table: {
       headerRows: 0,
       widths: ['*', 'auto'],
       body: honorTableBody
     }
   }
 ddContent.push(honorTable)
//  merritArray.forEach(ele => {
//    ddContent.push({text: `${ele.name} - ${ele.list} - ${ele.gpaPrint}`});
//  })
  var dd = {
   info: {
     title: 'Middle School Progress Report'
   },
  
   content: ddContent,
   styles: {
     topHeader: {
       fontSize: '8',
       italics: 'true',
       alignment: 'center',
       color: 'red',
       margin: [5, 0, 0, 0]
     },
     footerStyle: {
       fontSize: '8',
       alignment: 'center',
     },
     tableFont: {
       fontSize: '10'
     }
   }
  };

  return pdfMake.createPdf(dd).open()
}

