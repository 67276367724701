import React, {useGlobal} from 'reactn';


import { makeStyles } from '@material-ui/core/styles';
import data  from './Studentsgl.json';
import { AppBar } from '@material-ui/core';
import {Button, Paper, Card, Grid, Divider, Toolbar} from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
  },
  staffcard: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1%",
    textAlign: "left",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "primary",
    // outline: "1px solid orange",
    margin: "5px 5px",
  },
  cardTitle: {
    fonSize: "1.5rem",
  },
  barcodepanel: {
    display: "flex",
    flexDirection: "column",
  },
  barcode: {
    width: "50%",
    paddingLeft: "25%",
  },
  datapanel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    // width: "85%",
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    // outline: "1px solid orange",
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  scanDetails: {
    display: "flex",
    flexDirection: "column",
    
  },
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  
}));



function StudentCopy(props) {
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const [profileMenu, setProfileMenu] = React.useState(null);
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('copied');

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const copyInfo = (text) => {
    navigator.clipboard.writeText(text)
    setAlertOpen(true)
  }
  let sortedArrary = data.sort((a, b) => {
    return a.last_name.localeCompare(b.last_name);
  })

  let studentsDisplay = sortedArrary.map((ele) => {

    let subArray = [];
    let fields = Object.keys(ele);
    let excluding = fields.shift();
      for (let i = 0; i < fields.length; i++) {
        let key = fields[i];
        let text = ele[`${key}`]
        subArray.push(
          <Card 
          key={i}
          variant="outlined"
          className="line" >
            <Grid container direction="row">
              <Grid item lg={4} style={{paddingLeft: "2%"}}>
                {key}
              </Grid>
              <Grid item lg={4}>
                {text}
              </Grid>
              <Grid item lg={4}>
               <FileCopyRoundedIcon onClick={() => copyInfo(text)}/>
              </Grid>
            </Grid>
          
          </Card>)
  
      }
      
  
    return (
      <Paper elevation={6}>
        {subArray}
      </Paper>
    )
  })



  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Students
          </Typography>
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={() => props.history.push('/addclasses')}>Create New Class</MenuItem>
              <MenuItem onClick={() => props.history.push('/addticket')}>Create IT Support Ticket</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
  
      <Divider height={"2px"}></Divider>
      <div>
       {studentsDisplay[index]}
      </div>
      <div>
        
        <Button
         onClick={() => setIndex(index + 1)}
        >
           Next
        </Button>
        <Button
         onClick={() => setIndex(index - 1)}
        >
           Back
        </Button>
      </div>        
    
      <Snackbar open={alertOpen} autoHideDuration={1000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity} variant="outlined">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default StudentCopy;