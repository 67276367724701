import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from "@material-ui/core/Divider";
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
// import 'date-fns';
import Axios from 'axios';
import {Paper} from '@material-ui/core'
import GradingRow from './elemGrading-row'; 
import GradingComment from './elemComment';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  title: {
    flexGrow: 1,
  },
  staffcard: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1%",
    textAlign: "left",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "primary",
    // outline: "1px solid orange",
    margin: "5px 5px",
  },
  cardTitle: {
    fonSize: "1.5rem",
  },
  barcodepanel: {
    display: "flex",
    flexDirection: "column",
  },
  barcode: {
    width: "50%",
    paddingLeft: "25%",
  },
  datapanel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    // width: "85%",
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    // outline: "1px solid orange",
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  scanDetails: {
    display: "flex",
    flexDirection: "column",
    
  },
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  selects: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  }
  
}));





export default function Grading(props) {
  
  const classes = useStyles();
  let tempStudentArray;
  let myClassArray;
  let studentSelect = [];
  let gradesArray = [];
  let category = '';
  let subCategory = '';
 
  const [profileMenu, setProfileMenu] = React.useState(null);
 
  
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  
  const [Student, setStudent] = React.useState([]);
  const [myClasses, setMyClasses] = React.useState([]);
  const [currentGradeBand, setCurrentGradeBand] = React.useState('');
  const [currentStudent, setCurrentStudent] = React.useState({});
  const [grades, setGrades] = React.useState([])
  const [comments, setComments] = React.useState(null);
  

  if(!user.token) props.history.push('/')
  
  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL}/myclasses`, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      console.log('data', data);
      setMyClasses(data.data);
    })
    .catch(err => console.log('error', err));
  }, []);
 
  React.useEffect(() => {
    console.log('current grade changed');
    if(currentGradeBand){
      Axios.get(`${process.env.REACT_APP_URL}/studentByGrade/${currentGradeBand}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(array => {
        console.log('students for class:' , array);
        setStudent(array.data);
      })
      .catch(err => console.log('error', err));

    }
  }, [currentGradeBand, user]);

  React.useEffect(() => {
    console.log('current student changed', currentStudent);
    gradesArray = [];
    setComments(null)
    if(currentStudent._id){
      Axios.get(`${process.env.REACT_APP_URL}/gradesByStudent/${currentStudent._id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(array => {
        console.log('grades for student:' , array);
        setGrades(array.data);
      })
      // .then(() => setComments(null))
      .then(() => {
        return Axios.get(`${process.env.REACT_APP_URL}/commentsByStudent/${currentStudent._id}`, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
      })
      .then(data => {
        // console.log('data from comments', data.data)
        setComments(data.data[0])
       })
      .catch(err => console.log('error', err));

    }
  }, [currentStudent, user])

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const handleChange = event => {
    setCurrentGradeBand(event.target.value);
  };

  const handleStudentChange = event => {
    console.log('student change', event.target)
    let student = Student.filter(ele => ele._id === event.target.value);
    console.log('selected student', student[0]);
    setCurrentStudent(student[0]);
    setGrades([])
  }

  
 
  const handleCancel = () => {
    setAlertOpen(true);
    setAlertMessage('Cancelled');
    setCurrentGradeBand('');
    setStudent([]);
  }

  const replaceN = async () => {
    let response =  await Axios.get(`${process.env.REACT_APP_URL}/updateN`, {
      headers: { Authorization: `Bearer ${user.token}` }
    });
    console.log('update n response', response);
    if(response.data.ok === 1) {
      setAlertMessage('Updated');
      setSeverity('success');
      setAlertOpen(true);
    } else {
      setAlertMessage('falied');
      setSeverity('error');
      setAlertOpen(true);
    }
  }
  
  
  
  


studentSelect = Student.map((ele, index) => {
  return(<MenuItem value={ele._id} name={`bob`}>{ele.firstname} {ele.lastname}</MenuItem>)

})
myClassArray = [
    <MenuItem value={'K'}>Kindergarten</MenuItem>,
    <MenuItem value={'1'}>1st Grade</MenuItem>,
    <MenuItem value={'2'}>2nd Grade</MenuItem>,
    <MenuItem value={'3'}>3rd Grade</MenuItem>,
    <MenuItem value={'4'}>4th Grade</MenuItem>,
    <MenuItem value={'5'}>5th Grade</MenuItem>,


]
  
  
// if(grades.length > 0) {
//   for(let i = 0; i < grades.length; i ++) {
//     if(i === 0) gradesArray.push(<Paper>{grades[i].standard_id.category}</Paper>);
//     gradesArray.push(<GradingRow grades={grades[i]}/>);
//     if(i === 4) gradesArray.push(<Paper>{grades[i].standard_id.category} </Paper>);
//   }
  
// }
let displayArray = [];
let mainArray = []
if(grades.length > 0) {
  let tempCatArray = [];
  tempCatArray.push(grades[0])
  for(let i = 1; i < grades.length; i++) {
    
    if(grades[i].standard_id.category === tempCatArray[tempCatArray.length - 1].standard_id.category) {
      tempCatArray.push(grades[i]);
    } else {
      mainArray.push(tempCatArray);
      tempCatArray = [];
      tempCatArray.push(grades[i]);
    }
  }
  mainArray.push(tempCatArray);
  console.log('mainArray', mainArray);
  let dArray = []
  mainArray.forEach(categoryArray => {
    let tempCatArray = [];
    let tempSubCatArray = [];
    tempSubCatArray.push(categoryArray[0])
    for(let i = 1; i < categoryArray.length; i++) {
    
      if(categoryArray[i].standard_id.subCategory === tempSubCatArray[tempSubCatArray.length - 1].standard_id.subCategory) {
        tempSubCatArray.push(categoryArray[i]);
      } else {
        tempCatArray.push(tempSubCatArray);
        tempSubCatArray = [];
        tempSubCatArray.push(categoryArray[i]);
      }

    }
    tempCatArray.push(tempSubCatArray);
    dArray.push(tempCatArray)
  })
  console.dir('dArray', dArray, {depth: null});
  displayArray = dArray.map((ele, index) => {
    console.log('dArray map element', ele[0][0][`standard_id`][`category`]);
    return(
      <>
        <Paper style={{width: "80%", margin: "1% auto"}} elevation={6} variant="outlined">
          <Typography align="center" variant="h5">{ele[0][0][`standard_id`][`category`]}</Typography>
          {ele.map(subCat => {
            return(
              <Paper elevation={6} style={{marginBottom: "1%"}}>
                <Typography align="left" variant="h6" style={{paddingLeft: "1%"}}>
                  {subCat[0].standard_id.subCategory}
                </Typography>
                  {subCat.map(line => <GradingRow grades={line} />)}
              </Paper>
            )
          })}
          
        </Paper>
      </>
    )
  })
}



return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Grading
          </Typography>
          {currentStudent? <Typography variant="h5" className={classes.title}>{currentStudent.firstname}</Typography> : null }
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              {/* <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={() => props.history.push('/addclasses')}>Create New Class</MenuItem> */}
              <MenuItem onClick={() => props.history.push('/mprinting')}>Middleschool Print  Reports</MenuItem>
              <MenuItem onClick={() => props.history.push('/printing')}>Elementary Print Reports</MenuItem>
              <MenuItem onClick={() => props.history.push('/upload')}>Upload Trimester Grades</MenuItem>
              <MenuItem onClick={() => props.history.push('/uploadattendance')}>Upload Trimester attendance</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
  
      <Divider height={"2px"}></Divider>
      <div className={classes.selects}> 
          <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentGradeBand}
              onChange={handleChange}
            >
              <MenuItem value="" disabled>
                Select Class
              </MenuItem>
              {myClassArray}
            </Select>
            <Select
              displayEmpty
              labelId="student"
              id="studentSelect"
              value={currentStudent.firstname}
              onChange={handleStudentChange}
            >
              <MenuItem value="" disabled>
                Select Student
              </MenuItem>
              {studentSelect}
            </Select>

      </div>
      <Paper style={{width: "80%", margin: "0 auto"}} >
        <Grid container >
          <Grid item lg={9} sm={9}>
            <Typography color="primary" align="left" variant="h6">
            
            </Typography>
          </Grid>
          <Grid item lg={1} sm={1}>
            <Typography color="primary"  variant="p">
              Trimester 1
            </Typography>
          </Grid>
          <Grid item lg={1} sm={1}>
            <Typography color="primary"  variant="p">
              Trimester 2
            </Typography>
          </Grid>
          <Grid item lg={1} sm={1}>
            <Typography color="primary"  variant="p" >
              Trimester 3
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{display: "flex", justifyContent: "flex-end"}}>
       
        <button onClick={() => replaceN()}>Replace with N/A</button>

        
        </Paper>
      <Paper style={{maxHeight: "90vh", overflow: "scroll"}}>
        {displayArray.length > 0 ? displayArray : null}
        {comments ? <GradingComment comments={comments}/> : null}
      </Paper>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
