import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';

import {logo} from './base64';

// var fonts = {
// 	Roboto: {
// 		normal: 'fonts/Roboto-Regular.ttf',
// 		bold: 'fonts/Roboto-Medium.ttf',
// 		italics: 'fonts/Roboto-Italic.ttf',
// 		bolditalics: 'fonts/Roboto-MediumItalic.ttf'
// 	}
// };

// var PdfPrinter = require('pdfmake/src/printer');
// var printer = new PdfPrinter(pdfFonts);
// var fs = require('fs');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const teachers = [
    {grade: 'K', teacher: 'Jennifer Neafcy'},
    {grade: '1', teacher: 'Anna Zetsche'},
    {grade: '2', teacher: 'Elonna Visser'},
    {grade: '3', teacher: 'Mary Norton'},
    {grade: '4', teacher: 'Erika Alba'},
    {grade: '5', teacher: 'Stacy Robertson'},
]
const greyColor = '#ECECEC'
export default async function PDFMaker(students, token) {
  // console.log('students', students);
  
  let ddContent = [];
  
  for(let i = 0; i < students.length; i++) {
    let tableData = [[{},{text: "Trimesters", colSpan: 3, alignment: 'center'}, {}, {}],
    [{},{text: "F"}, {text: "W"}, {text: "S"}]];
    let id = students[i]['_id'];
    let sis = students[i].sis_id;
    let grades; 
    let comments;
    const promoTable = {
      table: {
        widths: [90, 10],
        body: [
          [{text:'Promoted to Grade', fontSize: '8'}, {text: '  ', fontSize: '8', }],
          [{text:'Placed in Grade', fontSize: '8'}, {text: '   ', fontSize: '8'}],
          [{text:'Retained in Grade', fontSize: '8'}, {text: '   ', fontSize: '8'}],
        ],
        alignment: 'center'
      }
    }
    
    let tempGrades = await axios.get(`${process.env.REACT_APP_URL}/gradesByStudent/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    grades = tempGrades.data;
    let tempComments = await axios.get(`${process.env.REACT_APP_URL}/commentsByStudent/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    comments = tempComments.data;
    // console.log('temp comments', comments)
    let displayGrades = [];
    displayGrades.push(
      {columns: [
        
        {text: `Subject`,style: {fontSize: '14', alignment: 'left', italics: 'true'} , width: '80%'},
        {text: `Grade\n\n`,style: {fontSize: '14', alignment: 'center', italics: 'true' }, width: '20%'}
      ]}
    )
      //create the display array of data from grade for each student
      let displayArray = [];
      let mainArray = []
      console.log('sis', sis);
      let studentAttendance = await axios.get(`${process.env.REACT_APP_URL}/attendanceByStudent/${sis}`);
      console.log('student attendance',studentAttendance.data[0]);
      let attendance0 = {t1Grade: '57', t2Grade: '58', t3Grade: '56', standard_id: {category: 'Attendance', subCategory: '', description: 'School Days'}};
      let attendance1 = {t1Grade: `${studentAttendance.data[0].t1Absent}`, t2Grade: `${studentAttendance.data[0].t2Absent}`, t3Grade: `${studentAttendance.data[0].t3Absent}`, standard_id: {category: 'Attendance', subCategory: '', description: 'Days Absent'}};
      let attendance2 = {t1Grade: `${studentAttendance.data[0].t1Tardy}`, t2Grade: `${studentAttendance.data[0].t2Tardy}`, t3Grade: `${studentAttendance.data[0].t3Tardy}`, standard_id: {category: 'Attendance', subCategory: '', description: 'Tardies (excused, unexcused)'}};
      grades.unshift(attendance2);
      grades.unshift(attendance1);
      grades.unshift(attendance0);
      if(grades.length > 0) {
        let tempCatArray = [];
        tempCatArray.push(grades[0])
        for(let i = 1; i < grades.length; i++) {
          
          if(grades[i].standard_id.category === tempCatArray[tempCatArray.length - 1].standard_id.category) {
            tempCatArray.push(grades[i]);
          } else {
            mainArray.push(tempCatArray);
            tempCatArray = [];
            tempCatArray.push(grades[i]);
          }
        }
        mainArray.push(tempCatArray);
        // console.log('mainArray', mainArray);
        let dArray = []
        mainArray.forEach(categoryArray => {
          let tempCatArray = [];
          let tempSubCatArray = [];
          tempSubCatArray.push(categoryArray[0])
          for(let i = 1; i < categoryArray.length; i++) {
          
            if(categoryArray[i].standard_id.subCategory === tempSubCatArray[tempSubCatArray.length - 1].standard_id.subCategory) {
              tempSubCatArray.push(categoryArray[i]);
            } else {
              tempCatArray.push(tempSubCatArray);
              tempSubCatArray = [];
              tempSubCatArray.push(categoryArray[i]);
            }

          }
          tempCatArray.push(tempSubCatArray);
          dArray.push(tempCatArray)
        })
        for (const arrayElem of mainArray) {
          //this is element of main category
          // console.log("in dArray element", arrayElem)
          let category = arrayElem[0];
          let mainCat = category.standard_id.category;
          let subCattemp = category.standard_id.subCategory;
          tableData.push([{text: `${mainCat}`, fontSize: '8', colSpan: 4, fillColor: greyColor, alignment: 'center', bold: true},{},{},{}]);
          if(subCattemp !== '') {
            tableData.push([{text: `${subCattemp}`, fontSize: '8', colSpan: 4, fillColor: greyColor, bold: true},{},{},{}])
          }

          for(const subCat of arrayElem) {
            if(subCat.standard_id.subCategory !== subCattemp) {
              tableData.push([{text: `${subCat.standard_id.subCategory}`, fontSize: '8', colSpan: 4, fillColor: greyColor, bold: true },{},{},{}]);
              subCattemp = subCat.standard_id.subCategory;
            }
            // console.log('subcat', subCat);
            if(subCat.standard_id.description  !== ''){
              tableData.push([{text: `${subCat.standard_id.description}`, fontSize: '8' },{text: `${subCat['t1Grade']} `, fontSize: '8', alignment: 'center', border: [true, true, true, true] },{text: `${subCat['t2Grade']} `, fontSize: '8',  alignment: 'center', border: [true, true, true, true]},{text: `${subCat['t3Grade']} `, fontSize: '8',  alignment: 'center',border: [true, true, true, true]}])
            }
          }
          tableData.push([{text: `\n`, fontSize: '8', colSpan: 4},{},{},{}])
        }
      }
      


    //grades itemization
    // for (const element of grades) {
         
    //   // table data go here
    //     tableData.push([{text: `${element.standard_id.description}`, fontSize: '10'},{text: `${element['t1Grade']} `, fontSize: '10'},{text:`${element['t2Grade']} `, fontSize: '10'},{text: `${element['t3Grade']} `, fontSize: '10'}])
    // };
    //end grades itemization

    let gradeBand = students[i].grade;
    let thisTeacher = teachers.filter(ele => students[i].grade === ele.grade)
    ddContent.push(
      {columns: [
        {text: '', width:'*'},
        {image: logo , alignment: 'center', width: 150},
        {text: '', width:'*'},
        // {image: logo , alignment: 'center'},
      ]},
      {text: ` \n`,style: {fontSize: '14', alignment: 'center'} },
      {text: `Grade ${gradeBand} Progress Report\n\n`,style: {fontSize: '14', alignment: 'center'} },
      // {text: `${displayDate}\n\n`,style: {fontSize: '12', alignment: 'center'} },
      {text: `Student:  ${students[i].firstname} ${students[i].lastname}\n`, fontSize: '10'},
      {text: `Teacher: ${thisTeacher[0].teacher}\n`, fontSize: '10'},
      {text: `Principal: Kristen Okabayashi\n`, fontSize: '10'},
      {text: `School Year: 2022 - 2023\n\n`, fontSize: '10'},
      {text: `Standards Key`, fontSize: '10', alignment: 'right'},
      {columns: [
        [
          promoTable,
          {text: `\n`, fontSize: '8', alignment: 'left'},
         
        ],
        [
          {text: `\n\n`, fontSize: '8', alignment: 'left'},
          {text: `_____________________________`, fontSize: '10', alignment: 'center'},
          {text: `Teacher Signature:`, fontSize: '10', alignment: 'center'},
        ],

        [
          {text: `Exceeds Expectations:  4`, fontSize: '8', alignment: 'right'},
          {text: `Meets Expectations:  3`, fontSize: '8', alignment: 'right'},
          {text: `Approaching Expectations:  2`, fontSize: '8', alignment: 'right'},
          {text: `Not Approaching Expectations:  1`, fontSize: '8', alignment: 'right'},
          {text: `Not Assessed at this time:  N/A`, fontSize: '8', alignment: 'right'},

        ]
      ]

    },
   
          

    
     
    );
   
    // ddContent.push(displayGrades);
    let tableTry = {
  
      layout: 'lightHorizontalLines',
      // layout: 'noBorders',
      // layout: {
      //   defaultBorder: false
      // },
      table: {
        headerRows: 2,
        widths: ['*', 20, 20 , 20],
        body: tableData,
      }
    }
    let commentsTable = {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 2,
        widths: ['*'],
        body: [
          [{text: `Teacher Comments`, fillColor: greyColor, alignment: 'center', bold: true}],
          [{text: `Trimester 1`}],
          [`${comments[0][`t1Comment`] ? comments[0][`t1Comment`] : ''}`],
          [{text: ""}],
          [{text: `Trimester 2`}],
          [`${comments[0][`t2Comment`] ? comments[0][`t2Comment`] : ''}`],
          [{text: ""}],
          [{text: `Trimester 3`}],
          [`${comments[0][`t3Comment`] ? comments[0][`t3Comment`] : ''}`],
          [{text: ""}],
        ],
      },
      // pageBreak: gradeBand === '3'? 'after':'before',
      pageBreak: 'beforeOdd',
      
      
        
       
    }
     ddContent.push(tableTry);
     ddContent.push(commentsTable);
     ddContent.push({text: '', pageBreak: 'afterEven' })
     

     
     
     
   

  } //end students for loop


   
  var dd = {
   info: {
     title: 'Midterm Progress Report'
   },
  //  footer: {columns: [
  //    {text: `Name`, style: {fontSize: '8', alignment: 'center'} },
    
  //  ]},
   content: ddContent,
   pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    
    return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
  
    },
   
 };
//  var pdfDoc = printer.createPdfKitDocument(dd);
// pdfDoc.pipe(fs.createWriteStream('./pdfs/test.pdf'));
// pdfDoc.end();
//  return pdfMake.createPdf(dd).print(`${students[0][`First Name`]}-${students[0][`Last Name`]}.pdf`);
 return pdfMake.createPdf(dd).open();
}

