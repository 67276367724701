import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Card from '@material-ui/core/Card';
import Divider from "@material-ui/core/Divider";
import CardContent from '@material-ui/core/CardContent';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InputLabel from '@material-ui/core/InputLabel';
import Axios from 'axios';
import { Container } from '@material-ui/core';
import { green} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#ffffff"
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
    color: "#ffffff"
  },
  staffcard: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1%",
    textAlign: "left",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "primary",
    margin: "5px 5px",
  },
  cardTitle: {
    fonSize: "1.5rem",
  },
  subheading: {
    backgroundColor: "#fffff",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  datepicker: {
    marginLeft: "15%",
    width: "50%",
  },
  searchBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    justifyItems: "space-evenly",
    marginBottom: "5%",
  },
  select: {
    '&:before': {
      borderColor: "#fffff",
  },
  '&:after': {
      borderColor: "#ffffff",
  }
  },
  icon: {
    fill: "#ffffff",
},
  
}));

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);



export default function Admin(props) {
  
  const classes = useStyles();
  let tempStudentArray;
  let classSelectArray;
  
  const [profileMenu, setProfileMenu] = React.useState(null);
 
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [classCode, setClassCode] = React.useState('');
  const [currentClassList, setCurrentClassList] = React.useState([])
  const [allStudents, setAllStudents] = React.useState([]);
  const [allClasses, setAllClasses] = React.useState([]);
  if(!user.token) props.history.push('/')
 
  //get all students grade 5 - 8
 React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL}/allstudents`, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      console.log('data', data);
      setAllStudents(data.data);
    })
    .catch(err => console.log('error', err));
 }, [])


 //get all class lists
 React.useEffect(() => {
  Axios.get(`${process.env.REACT_APP_URL}/allclasses`, {
    headers: { Authorization: `Bearer ${user.token}` }
  })
  .then(data => {
    console.log('data', data);
    setAllClasses(data.data);
  })
  .catch(err => console.log('error', err));
}, [])

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const handleChange = event => {
    setClassCode(event.target.value);
    Axios.get(`${process.env.REACT_APP_URL}/setstudents/${event.target.value}`, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      setCurrentClassList(data.data);
    })
    .catch(err => console.log('error', err));
  };
  const handleRadioChange = (event, index) => {
    let newArray = [...currentClassList];
    if(newArray.includes(event.target.value)) {
      let tempArray = newArray.filter(ele => ele !== event.target.value)
      setCurrentClassList(tempArray);
    } else {
      newArray.push(event.target.value);
      setCurrentClassList(newArray)
    }
   
  };

  const handleUpdate = () => {
    Axios.put(`${process.env.REACT_APP_URL}/updatestudents/${classCode}`, {students: currentClassList},
    {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      setAlertMessage('Updated');
      setAlertOpen(true);
    })
    .catch(err => {
      setAlertOpen(true);
      setAlertMessage('Opps try again')
  });
  }
  const handleCancel = () => {
    setAlertOpen(true);
    setAlertMessage('Cancelled');
    setCurrentClassList([]);
    setClassCode('')
  }
tempStudentArray = allStudents.map((ele, index) => {
  return (
    <Card  elevation={3} className={classes.card} key={index}>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography  color="primary"  variant="h6" style={{textTransform: "capitalize"}}>
                {ele.firstname} {ele.lastname}
              </Typography>
              
            </Grid>
            <Grid item xs={3}>
            <Typography  color="primary"  variant="h6" style={{textTransform: "capitalize"}}>
                {ele.grade}
              </Typography>
            </Grid>
            <Grid item xs={3}>
            <GreenRadio
              checked={currentClassList.includes(ele._id)}
              onClick={e => handleRadioChange(e, index)}
              value={ele._id}
              
              name="radio-button-demo"
              size="small"
              />
            </Grid>
            
          </Grid>
          
        </CardContent>
      </Card>
    )
  })

  
classSelectArray = allClasses.map((ele, index) => {
  return(
  <MenuItem value={ele._id}key={index} >{ele.description}</MenuItem>
  )
}) 

return (
  <div className={classes.root}>
    <AppBar position="sticky">
      <Toolbar>
        <IconButton 
        onClick={() => props.history.push('/')}
        color="inherit"
        >
          <HomeIcon fontSize="large"/>
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Assign Students to Classes
        </Typography>
        <Typography variant="h6" className={classes.title}>
          <Select
          displayEmpty
          labelId="select-class"
          id="select-class"
          value={classCode}
          onChange={handleChange}
          className={classes.select, classes.title}
            inputProps={{
                classes: {
                    icon: classes.icon,
                },
            }}
          >
          <MenuItem value="" disabled>
            Select Class
          </MenuItem>
          {classSelectArray}
          </Select>
        </Typography>
        <IconButton 
          edge="end" 
          color="inherit" aria-label="menu"
          onClick={e => setProfileMenu(e.currentTarget)}>
          {/* <AccountCircleIcon 
            aria-controls="profile-menu" aria-haspopup="true"
            fontSize="large"
            /> */}
          <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
        </IconButton>
        <Menu
            id="profile-menu"
            anchorEl={profileMenu}
            keepMounted
            open={Boolean(profileMenu)}
            onClose={e => setProfileMenu(null)}
            >
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
        {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
      </Toolbar>
    </AppBar>

    <Divider height={"2px"}></Divider>
    <Container>
      <Grid container className={classes.footer}>
      <Grid item xs={6} >
        <Typography color="primary"  variant="h6">
          Name
        </Typography>
      </Grid>
      <Grid item xs={3} >
        <Typography color="primary"  variant="h6">
          Grade
        </Typography>
      </Grid>
      <Grid item xs={3} >
        <Typography color="primary"  variant="h6">
          Class Members
        </Typography>
      </Grid>
    </Grid>
    {tempStudentArray}
    </Container>
    <Grid container className={classes.footer} spacig={1} >
      
      <Grid item  xs={6} align="center">
        <Button color="primary" variant="contained" onClick={() => handleCancel()}>Cancel</Button>
      </Grid>
      <Grid item  xs={6} align="center">
        <Button color="primary" variant="contained" onClick={() => handleUpdate()}>Update</Button>
      </Grid>
    </Grid>
    <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity={severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  </div>
);
}
