import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
// import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
// import 'date-fns';
import Axios from 'axios';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  title: {
    flexGrow: 1,
  },
  staffcard: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1%",
    textAlign: "left",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "primary",
    // outline: "1px solid orange",
    margin: "5px 5px",
  },
  cardTitle: {
    fonSize: "1.5rem",
  },
  barcodepanel: {
    display: "flex",
    flexDirection: "column",
  },
  barcode: {
    width: "50%",
    paddingLeft: "25%",
  },
  datapanel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    // width: "85%",
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    // outline: "1px solid orange",
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  scanDetails: {
    display: "flex",
    flexDirection: "column",
    
  },
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  selects: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  textfield: {
    backgroundColor: '#fcd792',
    textAlign: "center",
  }
  
}));





export default function GradingRow(props) {
  
  const classes = useStyles();
  
  
  const [grades, setGrades] = React.useState(props.grades);
  const [user, setuser] = useGlobal('user');

  const handleGradeChange = async e => {
     setGrades({...grades, [e.target.name]: e.target.value})
    handleUpdate(e.target.name, e.target.value)
  }
  
  const handleUpdate = (termToUpdate, grade) => {
    console.log('termToUpdate')
    Axios.put(`${process.env.REACT_APP_URL}/updateGrade`,{
      id: grades._id,
      term: termToUpdate,
      grade: grade
    }, {headers: { Authorization: `Bearer ${user.token}` }}, 
      )
      .then(data => console.log('data from put',data))
      .catch(err => console.log(err));
  }
// console.log('in grading row props::', props)

return (
  <Card variant="outlined">
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item lg={9} sm={9}>
        <Typography color="primary" align="left" variant="p" style={{paddingLeft: "1%"}}>
          {grades.standard_id.description}
        </Typography>
      </Grid>
      {/* <Grid item lg={1} sm={1} >
        <Typography color="primary" align="left" variant="p" >
          {grades.standard_id.order}
        </Typography>
      </Grid> */}
      <Grid item lg={1} sm={1}>
        <TextField variant="outlined" 
        // className={classes.textfield}
        value={grades.t1Grade === 0 ?  '' : grades.t1Grade}
        onChange={handleGradeChange}
        // onBlur={handleUpdate}
        name="t1Grade"
        >
        
        </TextField>
      </Grid>
      <Grid item lg={1} sm={1}>
        <TextField variant="outlined" 
        // className={classes.textfield}
        value={grades.t2Grade === 0 ? '' : grades.t2Grade}
        onChange={handleGradeChange}
        // onBlur={handleUpdate}
        name='t2Grade'
        >
        
        </TextField>
      </Grid>
      <Grid item lg={1} sm={1}>
        <TextField variant="outlined" 
         className={classes.textfield}
         value={grades.t3Grade === 0 ? '' : grades.t3Grade}
         onChange={handleGradeChange}
        //  onBlur={handleUpdate}
         name='t3Grade'
        >
        
        </TextField>
      </Grid>
      
    </Grid>
  </Card>
  );
}
