import React from 'reactn'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  logo: {
    // height: "10%",
    // transform: "rotate(45deg)",
    
  },
  logoHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    
    marginBottom: "2%",

  },
}));

export default function LogoHeader(props) {
  const  classes = useStyle();
 return (
  <div style={{width: `${props.width}%`}} className={classes.logoHeader}>

  <Grid container spacing={0} >
    {/* <Grid item xs={12} sm={12} ml={4}>
      <Typography  style={{textAlign: "center", fontSize: "2em"}}>
      CHECK IN
      </Typography>
    </Grid>
    <Grid item xs={12} sm={12} ml={4}>
      <Typography >
      <img src={require('./checkinlogo.png')} className={classes.logo} alt="logo"></img>
      </Typography>
    </Grid>
    <Grid item xs={12} sm={12} ml={4}>
      <Typography  style={{textAlign: "center", fontSize: "2em"}} >
      SAVE LIVES
      </Typography>
    </Grid> */}
    <Grid item xs={12}>
      <img src={require('./newlogo.png')} className={classes.logo} alt="logo"></img>
    </Grid>
  </Grid>
  </div>
 )
}