

export  default function Decimal (x) {
  let returnValue = 0;
  if (x < 60) returnValue = 0.00;
  if (x > 60 && x <= 61) returnValue = 0.70;
  if(x >61 && x <= 62) returnValue = 0.70;
  if(x > 62 && x <= 63) returnValue = 0.70;
  if(x > 63 && x <= 64) returnValue = 1.0;
  if(x > 64 && x <= 65) returnValue = 1.00;
  if(x > 65 && x <= 66) returnValue = 1.00;
  if(x > 66 && x <= 67) returnValue = 1.00;
  if(x > 67 && x <= 68) returnValue = 1.30;
  if(x > 68 && x <= 69) returnValue = 1.30;
  if(x > 69 && x <= 70) returnValue = 1.30;
  if(x > 70 && x <= 71) returnValue = 1.70;
  if(x > 71 && x <= 72) returnValue = 1.70;
  if(x > 72 && x <= 73) returnValue = 1.70;
  if(x > 73 && x <= 74) returnValue = 2.00;
  if(x > 74 && x <= 75) returnValue = 2.00;
  if(x > 75 && x <= 76) returnValue = 2.00;
  if(x > 76 && x <= 77) returnValue = 2.00;
  if(x > 77 && x <= 78) returnValue = 2.30;
  if(x > 78 && x <= 79) returnValue = 2.30;
  if(x > 79 && x <= 80) returnValue = 2.30;
  if(x > 80 && x <= 81) returnValue = 2.7;
  if(x > 81 && x <= 82) returnValue = 2.7;
  if(x > 82 && x <= 83) returnValue = 2.7;
  if(x > 83 && x <= 84) returnValue = 3.0;
  if(x > 84 && x <= 85) returnValue = 3.0;
  if(x > 85 && x <= 86) returnValue = 3.00;
  if(x > 86 && x <= 87) returnValue = 3.0;
  if(x > 87 && x <= 88) returnValue = 3.30;
  if(x > 88 && x <= 89) returnValue = 3.30;
  if(x > 89 && x <= 90) returnValue = 3.30;
  if(x > 90 && x <= 91) returnValue = 3.70;
  if(x > 91 && x <= 92) returnValue = 3.70;
  if(x > 92 && x <= 93) returnValue = 3.70;
  if(x > 93 && x <= 94) returnValue = 4.00;
  if(x > 94 && x <= 95) returnValue = 4.00;
  if(x > 95 && x <= 96) returnValue = 4.00;
  if(x > 96 && x <= 97) returnValue = 4.00;
  if(x > 97 && x < 98) returnValue = 4.00;
  if(x >= 98) returnValue = 4.30;
  // if(x > 98 && x <= 100) returnValue = 4.30;
  // if(x >= 100) returnValue = 4.30;
  
  
console.log('x in gpa calc', x ,'-', returnValue )


  return(returnValue)
}