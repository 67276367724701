import React, {useGlobal}from 'reactn';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {IconButton} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import Divider from "@material-ui/core/Divider";
import CardContent from '@material-ui/core/CardContent';
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import 'date-fns';
import Axios from 'axios';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: "75%",
    marginLeft: "12.5%",
  },
 
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  lineItem1: {
    backgroundColor: '#de2810',
    marginTop: "1%"
  },
  lineItem2: {
    backgroundColor: '#f0a316',
    marginTop: "1%"
  },
  
  lineItem3: {
    backgroundColor: '#2fd620',
    marginTop: "1%"
  },
  
  
}));

export default function AddTicket(props) {
  
  const classes = useStyles();
  const [teachers, setTeachers] = React.useState([]);
  const [user, setUser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [profileMenu, setProfileMenu] = React.useState(null);
  const [location, setLocation] = React.useState('');
  const [ticketDescription, setTicketDescription] = React.useState('');
  const [allTickets, setAllTickets] = React.useState([]);
  let ticketsArray = [];

 React.useEffect(() => {
    getAllTickets()
 
 }, []);

 const getAllTickets = () => {
  Axios.get(`${process.env.REACT_APP_URL}/alltickets/${user.id}`, 
  {
    headers: {Authorization: `Bearer ${user.token}`}
  }
  )
  .then(data => {
    console.log("data from all tickets", data.data);
    setAllTickets(data.data);
  })
  .catch(err => console.log(err));
 }
 
 const handleLogOut = () => {
   let userDomain = user.username.split("@")[1];

  localStorage.clear();
  setUser({
    user: {
      username: null,
      telephone: null,
      name: null,
      address: null,
      token: null,
    }
    
  })
  if(userDomain === 'seattlelutheran.org') props.history.push('/slhs')
  if(userDomain === 'hopeseattle.org') props.history.push('/') 
  
  
  }
  console.log('User', user);
  const createTicket = (e) => {
    if(user && ticketDescription !== '' && location !== '') {
      Axios.post(`${process.env.REACT_APP_URL}/newticket`, 
      {
        description: `${ticketDescription}`,
        name: `${user.name}`,
        ownerid: `${user.id}`,
        location: `${location}`
      },
      {
        headers: {Authorization: `Bearer ${user.token}`}
      }
      )
      .then(data => {
        console.log('data from create ticket', data);
        setAlertOpen(true);
        setAlertMessage('New Ticket Created');
        setLocation('');
        setTicketDescription('');
      })
      .then(() => {
        getAllTickets()
      })
      .catch(err => console.err(err));

    } else {
      setAlertOpen(true);
        setAlertMessage('You need a description and an location');
    }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const deleteTicket = (eleId) => {
    Axios.delete(`${process.env.REACT_APP_URL}/ticket/${eleId}`, 
  {
    headers: {Authorization: `Bearer ${user.token}`}
  }
  )
  .then(() => {
    setAlertOpen(true);
    setAlertMessage('Ticket Deleted');
    getAllTickets();
  })
  .catch(err => {
    setAlertOpen(true);
    setAlertMessage('Error Deleting Ticket, please try again later');
    console.log(err)
  })
  }

  const goHome = () => {
    let domainUser = user.username.split('@')[1];
    console.log('domainUser', domainUser);
    if (domainUser === 'seattlelutheran.org') {
      console.log('pushing to slhs home');
      props.history.push('/slhs');
    } else {
      props.history.push('/');
    }
  }


  if(!user.token) props.history.push('/');

 ticketsArray = allTickets.map((ele, index) => {
   let dateStr = new Date(ele.updatedAt).toString().split("GMT")[0];
   let colorClass;
   if(ele.status === 'pending') colorClass = classes.lineItem1;
   if(ele.status !== 'pending' && ele.status !== 'complete') colorClass = classes.lineItem2;
   if(ele.status === 'complete') colorClass = classes.lineItem3;
    return (
      <Card  elevation={3} className={colorClass}  key={index} >
          <CardContent>
            <Grid container>
              <Grid item md={2}>
                <Typography  color="primary"  variant="body1" style={{textTransform: "capitalize", backgroundColor: "white"}}>
                  {`${dateStr}`}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography  color="primary"  variant="body1" style={{textTransform: "capitalize",backgroundColor: "white"}}>
                  {ele.name}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography  color="primary"  variant="body1" style={{textTransform: "capitalize",backgroundColor: "white"}}>
                    {ele.location}
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography  color="primary"  variant="body1" style={{textTransform: "capitalize",backgroundColor: "white"}}>
                      {ele.description}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography  color="primary"  variant="body1" style={{textTransform: "capitalize",backgroundColor: "white"}}>
                      {ele.status}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <DeleteIcon onClick={() => deleteTicket(ele._id)} />
              </Grid>
              
            </Grid>
            
          </CardContent>
        </Card>
      )
    })
    console.log('ticekts array', ticketsArray);
  return (
   
      <div className={classes.paper}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => goHome()}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Create IT Support Ticket
          </Typography>
          <Typography variant="body1" className={classes.user}>
            {user.name}
          </Typography>
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
              <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />

              
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
      <Divider height={"2px"}></Divider>
     
        <form className={classes.form}  noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="location"
            label="Location / room"
            name="location"
            value={location}
            
            onChange={e => setLocation(e.target.value)}
           
            
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="ticket-description"
            label="Problem or Requirement"
            name="ticketDesc"
            value={ticketDescription}
            
            onChange={e => setTicketDescription(e.target.value)}
           
            
          />
         
          
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => createTicket()}
          >
            Sumbit Ticket
          </Button>
          
        </form>
        <Container>
          {ticketsArray}

        </Container>
        <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      </div>
    
  );
}