import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';
import {logo} from './base64';

import letterGrade from './letterGrade';

const greyColor = '#ECECEC';
const teachers = [{name: 'Tim Allen', grade: '6'}, {name: 'Tim Caudle', grade: '7'}, {name: 'Sara Kunkel', grade: '8'}]
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default async function PDFMakerMidTerm(students) {
  console.log('creating pdf file')
  let ddContent = [];
  for (let i = 0; i < students.length; i++) {
      // console.log('student', students[i]);
      
      let thisTeacher = teachers.filter(ele => ele.grade === students[i].grade)
      // let gradesM = await axios.get(`${process.env.REACT_APP_URL}/middleGradesMidterm/${students[i].sis_id}`);
      let gradesM = await axios.get(`${process.env.REACT_APP_URL}/getMidTermGrade/${students[i].sis_id}`);
      let studentGrades = gradesM.data;
      console.log('student Grades', studentGrades);
      let classList = [];
    // this is the heading for the classes table.
      classList.push([{text: 'Classes', fillColor: greyColor}, {text: 'Percent',fillColor: greyColor, alignment: 'center'}, {text: 'Grade', fillColor: greyColor, alignment: 'center'}])
      // this is the body fo rthe classes table
      for (let x = 0; x < studentGrades.length; x ++) {
        let tempGrade = studentGrades[x];
        let tempLetterGrade = letterGrade(tempGrade.mid2);
        classList.push([{text: `${studentGrades[x][`Course Name`]}`, fontSize: '10'},{text: `${tempGrade.mid2.toFixed(2)}`, fontSize: '10', alignment: 'center'},{text: `${tempLetterGrade}`, fontSize: '10', alignment: 'justified'}]);
      }

    ddContent.push(
      {columns: [
        {text: '', width:'*'},
        {image: logo , alignment: 'center', width: 150},
        {text: '', width:'*'},
        // {image: logo , alignment: 'center'},
      ]},
      {text: '\n\n'},
      {columns: [
        [
          {text: `${students[i].firstname} ${students[i].lastname}`, alignment: 'center', fontSize: '14', bold: true},
          {text: 'Student', alignment: 'center', fontSize: '10'},
          {text: '\n'},
        ],
        [
          {text: '4456 42nd Ave SW', width: "*", alignment: 'center', fontSize: '10'},
          {text: 'Seattle, WA, 98126', alignment: 'center', fontSize: '10'},
          {text: 'hopeseattle.org', alignment: 'center', fontSize: '10'},
          {text: '(206) 935-8500\n\n', alignment: 'center', fontSize: '10'}]
        ]
      },
      {text: 'Mid Trimester Progress Report', width: "*", alignment: 'center', fontSize: '12'}, 
      {text: `${students[i].grade}th Grade 2022 - 2023\n\n`, alignment: 'center', fontSize: '10'},
      {
        columns: [
          [ 
            {text: `${thisTeacher[0].name}`, alignment: 'center', width: '*', fontSize: '12' },
            {text: 'Teacher', alignment: 'center', width: '*', fontSize: '10' },
          ],
          [
            {text: 'Kristen Okayabashi', alignment:'center', fontSize: '12', width: 'auto'},
            {text: 'Principal\n\n\n', fontSize: '10', alignment: 'center', width: 'auto'},
          ]
        ]
      },
       {text: 'Mid Trimester Grades     ', alignment: 'right'},
             {
            table: {
              headerRows: 1,
              widths: ['*',60,60],
              body: classList,
            }
          },
               
      {text: '', pageBreak: i < students.length-1 ? 'after' : ''},
      )
    }
   // end of if statement

  var dd = {
   info: {
     title: 'Middle School Progress Report'
   },
  
   content: ddContent,
   footer: [
    {text: 'Parent Signature: _________________________________ \nPlease sign and return a copy to your child\'s teacher. \n' , fontSize: '10', alignment: 'center', width: 'auto'},
    {text: 'This grade is based on currently submitted and graded work, if there is still outstanding / ungraded work it could affect this grade.',fontSize: '10', alignment: 'center', width: 'auto'},
    {text: 'Please check Schoology for any missing work.', fontSize: '10', alignment: 'center', width: 'auto'},
   ],
   styles: {
     topHeader: {
       fontSize: '8',
       italics: 'true',
       alignment: 'center',
       color: 'red',
       margin: [5, 0, 0, 0]
     },
     footerStyle: {
       fontSize: '10',
       alignment: 'center',
     },
     tableFont: {
       fontSize: '10'
     }
   }
  };

  return pdfMake.createPdf(dd).open()
}

