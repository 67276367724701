import React, {setGlobal} from 'reactn';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Axios from 'axios';
// import addReactNDevTools from 'reactn-devtools';
// addReactNDevTools();



const theme = createMuiTheme({
  palette: {
    primary: {
      // main:'#40E0CB',
      // main: '#FB5607',
      main: "#0a1d61" 
    } ,
    
    secondary: {
      // main: '#601ab0',
      main: '#fda915'
    },
  },
});
if(localStorage.token) {
  Axios.get(`${process.env.REACT_APP_URL}/user/`, {
    headers: {  Authorization: `Bearer ${localStorage.token}`  }
  })
  .then(user => {
    setGlobal({
      user: {
        id: user.data._id,
        name: user.data.name,
        username: user.data.username,
        token: localStorage.token,
      },
      
    })
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>,
      document.getElementById('root')
    );
  })
  .catch(err => {
    setGlobal({
      user: {
        id: null,
        name: null,
        username: null,
        token: null,
        
      },
      
    })
    localStorage.clear();
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>,
      document.getElementById('root')
    );

  })
} else {
  setGlobal({
    user: {
      id: null,
      name: null,
      username: null,
      
      token: null,
      
    },
    
  })
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById('root')
  );

}


// ReactDOM.render(
//   <ThemeProvider theme={theme}>
//     <App />
//   </ThemeProvider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
