import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Card from '@material-ui/core/Card';
import Divider from "@material-ui/core/Divider";
import { Checkbox } from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
// import 'date-fns';
import Axios from 'axios';
import {Paper} from '@material-ui/core'
import PDFMaker from './createPDF';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  title: {
    flexGrow: 1,
  },
  studentList: {
    width: "50%",
    margin: "0 auto",
    marginTop: "1%"
  },
  selects: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  buttonBox: {
    width: "20%",
    margin: "1% auto 1%",
   
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center"
  }
  
  
  
  
  
}));





export default function Printing(props) {
  
  const classes = useStyles();
  let tempStudentArray;
  let myClassArray;
  let studentSelect = [];
  let gradesArray = [];
  let category = '';
  let subCategory = '';
 
  const [profileMenu, setProfileMenu] = React.useState(null);
 
  
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  
  const [Student, setStudent] = React.useState([]);
  const [myClasses, setMyClasses] = React.useState([]);
  const [currentGradeBand, setCurrentGradeBand] = React.useState('');
  const [currentStudent, setCurrentStudent] = React.useState({});
  const [grades, setGrades] = React.useState([])
  const [comments, setComments] = React.useState(null);
  const [printAll, setPrintAll] = React.useState(false);
  

  if(!user.token) props.history.push('/')
  
  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL}/myclasses`, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      console.log('data', data);
      
      setMyClasses(data.data);
    })
    .catch(err => console.log('error', err));
  }, []);
 
  React.useEffect(() => {
    console.log('current grade changed');
    if(currentGradeBand){
      Axios.get(`${process.env.REACT_APP_URL}/studentByGrade/${currentGradeBand}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(array => {
        console.log('students for class:' , array);
        let studentsWithPrint =  array.data.map((ele) => {
          return ({...ele, printReport: false})
        })
        setStudent(studentsWithPrint);
      })
      .catch(err => console.log('error', err));

    }
  }, [currentGradeBand, user]);

  React.useEffect(() => {
    console.log('current student changed', currentStudent);
    gradesArray = [];
    setComments(null)
    if(currentStudent._id){
      Axios.get(`${process.env.REACT_APP_URL}/gradesByStudent/${currentStudent._id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(array => {
        console.log('grades fro student:' , array);
        setGrades(array.data);
      })
      // .then(() => setComments(null))
      .then(() => {
        return Axios.get(`${process.env.REACT_APP_URL}/commentsByStudent/${currentStudent._id}`, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
      })
      .then(data => {
        console.log('data from comments', data.data)
        setComments(data.data[0])
       })
      .catch(err => console.log('error', err));

    }
  }, [currentStudent, user]);

  

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const handleChange = event => {
    setCurrentGradeBand(event.target.value);
  };

  const handleStudentChange = event => {
    console.log('student change', event.target)
    let student = Student.filter(ele => ele._id === event.target.value);
    console.log('selected student', student[0]);
    setCurrentStudent(student[0]);
    setGrades([])
  }

  
 
  const handleCancel = () => {
    setAlertOpen(true);
    setAlertMessage('Cancelled');
    setCurrentGradeBand('');
    setStudent([]);
  }

  const handleCheck = (e, index) => {
    let set = new Set();
    let tempStudent = Student.map((ele, i) => {
      if(index === i) {
        return({
          ...ele, printReport: !Student[index].printReport
        })
      } else {
        return(ele)
      }
    })
    tempStudent.map(ele => set.add(ele.printReport))
    if(set.has(false)) {
      setPrintAll(false);
    }
    setStudent(tempStudent)

  }

  const handlePrintAll = () => {
    let tempPrintAll = printAll;
    if(!printAll) {
      let tempStudent = Student.map((ele, index) => {
        return({...ele, printReport: true})
      })
      setStudent(tempStudent);
    } else {
      let tempStudent = Student.map((ele, index) => {
        return({...ele, printReport: false})
      })
      setStudent(tempStudent);
    }
    setPrintAll(!tempPrintAll)
  }

  const handleCreatePDF = () => {
    let tempArray = Student.filter(ele => ele.printReport)
    // console.log('to print', tempArray)
    PDFMaker(tempArray, user.token);
  }

  
  
  
  



myClassArray = [
    <MenuItem value={'K'}>Kindergarten</MenuItem>,
    <MenuItem value={'1'}>1st Grade</MenuItem>,
    <MenuItem value={'2'}>2nd Grade</MenuItem>,
    <MenuItem value={'3'}>3rd Grade</MenuItem>,
    <MenuItem value={'4'}>4th Grade</MenuItem>,
    <MenuItem value={'5'}>5th Grade</MenuItem>,


]
  
  

let displayArray = [];
let mainArray = []
if(Student.length > 0) {
  
  displayArray = Student.map((ele, index) => {
    console.log('student element', ele)
    return(
      <>
        <Card className={classes.studentList} >
          <Grid container 
                direction="row"
                // justifyContent="center"
                alignItems="center"
          >
            <Grid item xs={5}>
              {ele.firstname}
            </Grid>
            <Grid item xs={5}>
              {ele.lastname}
            </Grid>
            <Grid item xs={2}>
              <Checkbox checked={ele.printReport}   onChange={e => handleCheck(e, index)}></Checkbox>
            </Grid>
          </Grid>
        </Card>
      </>
    )
  })
  displayArray.unshift(<>
    <Card className={classes.studentList} >
      <Grid container
       direction="row"
       justifycontent="center"
       alignItems="center"
      >
        <Grid item xs={10}>
         Print All
        </Grid>
        
        <Grid item xs={2}>
          <Checkbox checked={printAll}   onChange={() => handlePrintAll()}></Checkbox>
          {/* <SelectAll></SelectAll> */}
        </Grid>
      </Grid>
    </Card>
  </>)
}




return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Print Report Cards
          </Typography>
          
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              {/* <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={() => props.history.push('/addclasses')}>Create New Class</MenuItem>
              <MenuItem onClick={() => props.history.push('/addticket')}>Create IT Support Ticket</MenuItem> */}
              <MenuItem onClick={() => props.history.push('/grading')}>Grading</MenuItem>
              <MenuItem onClick={() => props.history.push('/mprinting')}>Middleschool Print  Reports</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
  
      <Divider height={"2px"}></Divider>
      <div className={classes.selects}> 
          <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentGradeBand}
              onChange={handleChange}
            >
              <MenuItem value="" disabled>
                Select Class
              </MenuItem>
              {myClassArray}
            </Select>
      </div>
     
      <Paper style={{maxHeight: "80vh", overflow: "scroll", margin: "0 auto", width: "80%"}}>
        {/* insert list of students here */}
        {displayArray.length > 0 ? displayArray : null}
        
      </Paper>
      {currentGradeBand ? <Paper className={classes.buttonBox}>
        <Button variant="contained" onClick={() => handleCreatePDF()}>
         Create PDF for Print
        </Button>
      </Paper> : null}
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
