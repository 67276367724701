import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Card from '@material-ui/core/Card';
import Divider from "@material-ui/core/Divider";
import CardContent from '@material-ui/core/CardContent';
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
// import 'date-fns';
import Axios from 'axios';
import {Paper, Chip} from '@material-ui/core'
import { Container } from '@material-ui/core';
import { green, red, yellow, blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    flexGrow: 1,
  },
  staffcard: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1%",
    textAlign: "left",
  },
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "primary",
    // outline: "1px solid orange",
    margin: "5px 5px",
  },
  cardTitle: {
    fonSize: "1.5rem",
  },
  barcodepanel: {
    display: "flex",
    flexDirection: "column",
  },
  barcode: {
    width: "50%",
    paddingLeft: "25%",
  },
  datapanel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    // width: "85%",
  },
  uploadPanel: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    // outline: "1px solid orange",
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  scanDetails: {
    display: "flex",
    flexDirection: "column",
    
  },
  subheading: {
    backgroundColor: "#a2a2a2",
    color: "white",
  },
  button: {
    width: "50%",
    marginLeft: "25%",
  },
  reasonDisplay: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  
}));

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const YellowRadio = withStyles({
  root: {
    color: yellow[600],
    '&$checked': {
      color: yellow[800],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
  root: {
    color: blue[600],
    '&$checked': {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const reasons = ['camera off', 'not responsive', 'left early', 'arrived late', 'distracting'];

export default function Admin(props) {
  
  const classes = useStyles();
  let tempStudentArray;
  let myClassArray;
 
  const [profileMenu, setProfileMenu] = React.useState(null);
 
  
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  
  const [studentState, setStudentState] = React.useState([]);
  const [myClasses, setMyClasses] = React.useState([]);
  const [currentClassCode, setCurrentClassCode] = React.useState('');

  if(!user.token) props.history.push('/')
  
  React.useEffect(() => {
    Axios.get(`${process.env.REACT_APP_URL}/myclasses`, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(data => {
      console.log('data', data);
      setMyClasses(data.data);
    })
    .catch(err => console.log('error', err));
  }, []);
 
  React.useEffect(() => {
    if(currentClassCode){
      Axios.get(`${process.env.REACT_APP_URL}/currentclass/${currentClassCode}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(data => {
        data.data.forEach(ele => {
          ele.attendance = 'Present';
          ele.reason = []
        })
        return data.data;
      })
      .then(array => {
        setStudentState(array);
      })
      .catch(err => console.log('error', err));

    }
  }, [currentClassCode])

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  const handleChange = event => {
    setCurrentClassCode(event.target.value);
  };
  const handleRadioChange = (event, index) => {
    let newArray = [...studentState];
   let newItem  = {...newArray[index]};
   newItem.attendance = event.target.value;
   newArray[index] = newItem;
  console.log('new array', newArray)
    setStudentState(newArray);
   
  };
  const handleCancel = () => {
    setAlertOpen(true);
    setAlertMessage('Cancelled');
    setCurrentClassCode('');
    setStudentState([]);
  }
  const handleSubmit = () => {
    let issuesArray = studentState.filter(ele => {
      if(ele.attendance === 'Issue' && ele.reason.length === 0) {
        return ele
      }
    })
    if(issuesArray.length > 0) {
      setAlertOpen(true);
      setAlertMessage('You need to select reasons for behavior')
    } else {
      Axios.post(`${process.env.REACT_APP_URL}/createattendance/`, {
        students: studentState,
        period: currentClassCode,
      }
      ,{
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(data => {
        console.log(data.data)
        setAlertOpen(true);
        setAlertMessage('Emails sent');
        setCurrentClassCode('')
      })
      .catch(err => console.log(err));

    }
  }
  const handleChipClick = (reason, index) => {
    
    let newArray = [...studentState];
    let newItem  = {...newArray[index]};
    if(newItem.reason.includes(reason)) {
      let temp = newItem.reason.filter(ele => ele !== reason);
      newItem.reason = temp;
    } else {
      newItem.reason.push(reason);
    }

    newArray[index] = newItem;
    setStudentState(newArray);
  }
  
tempStudentArray = studentState.map((ele, index) => {
  
  return (
    <Card  elevation={3} className={classes.card} key={index}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography  color="primary"  variant="h6" style={{textTransform: "capitalize"}}>
              {ele.firstname} {ele.lastname}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                {ele.index}
                <GreenRadio
                  checked={studentState[index]['attendance'] === 'Present'}
                  onChange={e => handleRadioChange(e, index)}
                  value="Present"
                  
                  name="radio-button-demo"
                  size="small"
                  />
              </Grid>
              <Grid item xs={3}>
                <RedRadio
                  checked={studentState[index]['attendance'] === 'Absent'}
                  onChange={e => handleRadioChange(e, index)}
                  value="Absent"
                  
                  name="radio-button-demo"
                  size="small"
                  />
              </Grid>
              <Grid item xs={3}>
                <YellowRadio
                  checked={studentState[index]['attendance'] === 'Issue'}
                  onChange={e => handleRadioChange(e, index)}
                  value="Issue"
                  name="radio-button-demo"
                  size="small"
                  />
              </Grid>
              <Grid item xs={3}>
                <BlueRadio
                  checked={studentState[index]['attendance'] === 'Tech'}
                  onChange={e => handleRadioChange(e, index)}
                  value="Tech"
                  name="radio-button-demo"
                  size="small"
                  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Paper 
        style={{display: (studentState[index]['attendance'] === 'Issue') ? "" : "none"}}
        className={classes.reasonDisplay}
        >
        {reasons.map((reason, i) => {
          return(
          <Chip 
            value={reason} 
            key={i} 
            label={reason} 
            style={{backgroundColor: ele.reason.includes(reason) ? "#fda915" : "#ffffff"}}
            variant="outlined" 
            onClickCapture={() => handleChipClick(reason, index)}
          />
          )
        })}
        </Paper>
      </CardContent>

    </Card>
    )
  })
    
myClassArray = myClasses.map((ele, index) => {
  return(
      <MenuItem key={index} value={ele._id}>{ele.description}</MenuItem>
  )
})
console.log('studentState',studentState)
console.log('global user', user);


return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
            color="inherit"
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Classes
          </Typography>
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={() => props.history.push('/addclasses')}>Create New Class</MenuItem>
              <MenuItem onClick={() => props.history.push('/addticket')}>Create IT Support Ticket</MenuItem>
              <MenuItem onClick={() => props.history.push('/studentcopy')}>Copy Student Info</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
  
      <Divider height={"2px"}></Divider>
      <Select
          displayEmpty
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentClassCode}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>
            Select Class
          </MenuItem>
          {myClassArray}
        </Select>
      <Container style={{display: currentClassCode ? "" : "none"}}>
        <Grid container className={classes.footer}>
        <Grid item xs={6} >
          <Typography color="primary"  variant="h6">
            Name
          </Typography>
        </Grid>
          <Grid item xs={6} >
            <Grid container>
              <Grid item xs={3} >
                <Typography color="primary"  variant="h6">
                  Present
                </Typography>
              </Grid>
              <Grid item xs={3} >
                <Typography color="primary"  variant="h6">
                  Absent
                </Typography>
              </Grid>
              <Grid item xs={3} >
                <Typography color="primary"  variant="h6">
                  Behavior Issue
                </Typography>
              </Grid>
              <Grid item xs={3} >
                <Typography color="primary"  variant="h6">
                  Technical Issue
                </Typography>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      {tempStudentArray}
      <Grid container className={classes.footer} spacing={1} >
        <Grid item  xs={6} align="center">
          <Button color="primary" variant="contained" onClick={() => handleCancel()}>Cancel</Button>
        </Grid>
        <Grid item  xs={6} align="center">
          <Button color="primary" variant="contained" onClick={() => handleSubmit()}>Submit</Button>
        </Grid>
      </Grid>
      </Container>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
