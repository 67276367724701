import React, {useGlobal} from 'reactn';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
// import DateFnsUtils from '@date-io/date-fns';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from '@material-ui/core/Select';
import { Checkbox } from '@material-ui/core';

import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer
} from '@material-ui/core';
// import 'date-fns';
import { CircularProgress } from '@material-ui/core';
import CSVReader from "react-csv-reader";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Prompt } from 'react-router';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
   
  title: {
    flexGrow: 1,
  },
  input: {
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #ccc",
  bordeRadius:" 5px",
  },
  tableDiv: {
    overflow: "hidden",
    // height: "80vh"
  },
  holder: {
    height: "65vh",
    width: "100%",
    marginTop: "150px",
    // outline: "1px solid green",
   overflow: "scroll"
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-evenly",
    // alignItems: "center",
  },
  progressBox: {
     display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  buttonBox: {
    marginTop: "2%",
    // outline: "1px solid orange",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"

  },
  selects: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    outline: "1px solid black",
    marginTop: "5%",
  },
  buttonHolder: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
  }
  
  
}));





export default function UploadCSV(props) {
  
  const classes = useStyles();
  let tempStudentArray;
  let myClassArray;
  let studentSelect = [];
  let gradesArray = [];
  let category = '';
  let subCategory = '';
 
  const [profileMenu, setProfileMenu] = React.useState(null);
 
  
  const [user, setuser] = useGlobal('user');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [processingErrors, setProcessingErrors] = React.useState(false);
  const [ button, setButton] = React.useState(false);
  const [currentTrimester, setCurrentTrimester] = React.useState(null)
  const [uploadButton, setUploadButton] = React.useState(false);
  
  
  const [CSVdata, setCSVdata] = React.useState([])
  

  if(!user.token) props.history.push('/')
  
  React.useEffect(() => {
    
    let numErrors = CSVdata.filter(ele => (ele.error && !ele.ignore));
    if(numErrors.length > 0) {
      setUploadButton(false)
    } else {
      setUploadButton(true)
    }
    console.log('The number of errors not ignoreed :', numErrors);
  }, [CSVdata]);
 
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.replace(/\W/g, "_")
  };

  const getError = async(element) => {
    let result = await axios.post(`${process.env.REACT_APP_URL}/checkErrors`, {
      sis_id: element.sis_id,
      courseCode: element.courseCode
    })
    console.log('result length', result.data.length)
    if(result.data.length === 1) return false;
    if(result.data.length === 0) return true;
  }
  const handleForce = async (data, fileInfo) => {
    console.log('file data', data)
    setProcessingErrors(true);
    let tempData = [...data]
    // console.log(data, fileInfo);
    for(const ele of tempData) {
      ele.ignore = false;
      ele.error = await getError(ele);
      // ele.error = false;
      ele.selected = false;
      ele.tempId = uuidv4();
    }
    setCSVdata(tempData);
    setProcessingErrors(false);
  };  

  const handleLogOut = () => {
    localStorage.clear();
    setuser({
      user: {
        username: null,
        telephone: null,
        name: null,
        address: null,
        token: null,
      }
      
    })
    props.history.push('/');
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
 
  const handleCancel = () => {
    setAlertOpen(true);
    setAlertMessage('Cancelled');
  }

  const handleSelect = (id) => {
    let tempArray = [...CSVdata];
    let selectedArray = tempArray.map((ele) => {
      if(ele.tempId === id) {
       ele.selected = !ele.selected;
      }
      return ele
    })
    let NumSelected = selectedArray.filter(ele => ele.selected)
    setCSVdata(selectedArray);
    if(NumSelected.length > 0) {
      setButton(true);
    } else {
      setButton(false)
    }
  }
  const handleIgnore = (id) => {
    let tempArray = [...CSVdata];
    let ignoreArray = tempArray.map((ele) => {
      if(ele.tempId === id) {
       ele.ignore = !ele.ignore;
      }
      return ele
    })
    
    setCSVdata(ignoreArray);
    
  }
   const handleFix = async () => {
     let recordsToFix = CSVdata.filter(ele => ele.selected);
     setProcessingErrors(true)
     console.log('recordsToFix', recordsToFix);
     let errorsFixed = 0;
     for(const ele of recordsToFix) {
       try{
         let data = await axios.post(`${process.env.REACT_APP_URL}/middleGradePost`, {
            Last: ele.last,
            first: ele.first,
            description: ele.description,
            category: ele.category ? ele.category : "",
            gradeBand: ele.gradeBand,
            sis_id: ele.sis_id,
            courseCode: ele.courseCode,
            weight: ele.weight,
            t1Grade: null,
            t2Grade: null,
            t3Grade: null,
            
         })
         console.log('data from fix', data)
         errorsFixed ++;

       } catch(err) {
         console.log(err)
       }
       
     }

    
     let tempData = [...CSVdata]
     // console.log(data, fileInfo);
     for(const ele of tempData) {
       ele.error = await getError(ele);
       ele.selected = false;
       ele.tempId = uuidv4();
     }
     
     setCSVdata(tempData);
     setProcessingErrors(false);
     setAlertOpen(true);
     setAlertMessage(`Fixed ${errorsFixed} records `)
   }
   const handleChange = event => {
    setCurrentTrimester(event.target.value);
  };

  const uploadRecords = async () => {
    setProcessingErrors(true);
    let updateCount = 0;
    if(CSVdata.length > 0) {
      let recordsToUpdate = CSVdata.filter(ele => !ele.ignore)
      for(const record of recordsToUpdate) {
        let result = await axios.post(`${process.env.REACT_APP_URL}/addTrimesterGrade`, {
          sis_id: record.sis_id,
          courseCode: record.courseCode,
          trimester: currentTrimester,
          grade: record.grade
        })
        if (result.status !== 204) console.log('error occured', record.sis_id, record.courseCode);
        updateCount ++;
      }
    }
    console.log('Records updated', updateCount);
    setAlertOpen(true);
    setAlertMessage(`Updated ${updateCount} records`)
    setProcessingErrors(false);
  }




return (
    <div className={classes.root}>
      <Prompt 
      when={CSVdata.length > 0}
      message="Are you sure you want to leave?"
      />
      <AppBar  >
        <Toolbar>
          <IconButton 
            onClick={() => props.history.push('/')}
           
          >
            <HomeIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Upload Trimester Grades
          </Typography>
          <IconButton 
            edge="end" 
            // className={classes.menuButton} 
            color="inherit" aria-label="menu"
            onClick={e => setProfileMenu(e.currentTarget)}>
            {/* <AccountCircleIcon 
              aria-controls="profile-menu" aria-haspopup="true"
              fontSize="large"
              /> */}
            <MenuIcon 
               aria-controls="profile-menu" aria-haspopup="true"
               fontSize="large"
              />
          </IconButton>
          <Menu
              id="profile-menu"
              anchorEl={profileMenu}
              keepMounted
              open={Boolean(profileMenu)}
              onClose={e => setProfileMenu(null)}
              >
              {/* <MenuItem onClick={() => props.history.push('/addstudents')}>Update Students</MenuItem>
              <MenuItem onClick={() => props.history.push('/addclasses')}>Create New Class</MenuItem> */}
              <MenuItem onClick={() => props.history.push('/mprinting')}>Middleschool Print  Reports</MenuItem>
              <MenuItem onClick={() => props.history.push('/printing')}>Elementary Print Reports</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          {/* <AccountCircleIcon fontSize="large" onClick={() => props.history.push('/profile')}></AccountCircleIcon> */}
        </Toolbar>
      </AppBar>
  
      {/* <Divider height={"20px"}></Divider> */}
    <div className={classes.selects}>
      <Select 
      value={currentTrimester}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      
      onChange={handleChange}
      >
      <MenuItem value={'t1'}>Trimeste 1</MenuItem>
      <MenuItem value={'t2'}>Trimeste 2</MenuItem>
      <MenuItem value={'t3'} >Trimeste 3</MenuItem>
      </Select >
    </div>
    <div>
      <a href={`data:text/csv;charset=utf-8,${encodeURI('sis_id,first,last,description,category,gradeBand,courseCode,weight,grade\nDo NOT change headings')}` }>Download Sample for Grades</a>
    </div>
      <div className={classes.holder}>

      
      
      {(CSVdata.length > 0 && !processingErrors)  ? 
      <div sx={{ width: "100%", marginTop: 100}}>
          <TableContainer sx={{maxHeigt: 500}} >
            <Table stickyHeader >
              <TableHead>
                <TableRow>
                  <TableCell>Ignore GradingRow</TableCell>
                  <TableCell>User Id</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Coure Name</TableCell>
                  <TableCell>Course Code</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Selected</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CSVdata.map((row) => (
                  <TableRow style={{backgroundColor: row.error ? '#fcedbd' : 'white'}} key={row.tempId}>
                     <TableCell><Checkbox checked={row.ignore} onClick={(e) => handleIgnore(row.tempId)} ></Checkbox></TableCell>
                    <TableCell>{row[`sis_id`]}</TableCell>
                    <TableCell>{row[`first`]}</TableCell>
                    <TableCell>{row[`last`]}</TableCell>
                    <TableCell>{row[`description`]}</TableCell>
                    <TableCell>{row[`courseCode`]}</TableCell>
                    <TableCell>{row[`grade`] }</TableCell>
                    <TableCell>{row[`category`] }</TableCell>
                    <TableCell>{row[`weight`] }</TableCell>
                    <TableCell><Checkbox checked={row.selected} onClick={(e) => handleSelect(row.tempId)}
                      color="primary"
                    ></Checkbox></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>_
          </TableContainer>
      
      </div> 
      : 
      <div className={classes.upload}>

      <CSVReader
      cssClass={classes.input}
      label="Select CSV file - Grades"
      onFileLoaded={handleForce}
      parserOptions={papaparseOptions}
    />
    </div>
      
      }
      {
        processingErrors ? 
        <div className={classes.progressBox}>

        <CircularProgress /> 
        <h3> Please be patient</h3>
        <h3> Do Not Leave Page</h3>
        </div>
        : null
      }
    </div>
    <div className={classes.buttonHolder}>
      <div className={classes.buttonBox}>
        <Button onClick={() => handleFix()} variant="contained" disabled={!button}>Fix Selected Records</Button>
      </div>
      <div className={classes.buttonBox}>
        <Button onClick={() => uploadRecords()} variant="contained" disabled={!uploadButton}>Upload Records</Button>
      </div>

    </div>
      
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
